import '@/styles/globals.css'
import type {AppProps} from 'next/app'
import {useState, useEffect} from 'react'
import {HeaderMenuColored} from '@/components/Headers'
import {HeaderMegaMenu} from '@/components/Headers2'
import {FooterSocial} from '@/components/Footer'
import {I18nextProvider} from 'react-i18next'
import i18n from '../../i18n'
import {MantineProvider, ColorSchemeProvider, ColorScheme} from '@mantine/core'
import {useRouter} from 'next/router'
import {QueryClient, QueryClientProvider} from 'react-query'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 5000,
            refetchOnWindowFocus: false,
        },
    },
})


export default function App({Component, pageProps}: AppProps) {
    const router = useRouter()
    const hideHeadersFooter = router.pathname === '/join'
    const [colorScheme, setColorScheme] = useState<ColorScheme>('light')

    useEffect(() => {
        const systemPreference = window.matchMedia('(prefers-color-scheme: dark)').matches
        setColorScheme(systemPreference ? 'dark' : 'light')
    }, [])

    const toggleColorScheme = () => {
        setColorScheme((prevScheme) => (prevScheme === 'light' ? 'dark' : 'light'))
    }

    return (
        <I18nextProvider i18n={i18n}>
            <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
                <MantineProvider theme={{colorScheme}} withGlobalStyles withNormalizeCSS>
                    {!hideHeadersFooter && <HeaderMegaMenu/>}
                    {!hideHeadersFooter && <div style={{height: '56px'}}/>}
                    <QueryClientProvider client={queryClient}>
                        <Component {...pageProps} />
                    </QueryClientProvider>
                    {!hideHeadersFooter && <FooterSocial/>}
                </MantineProvider>
            </ColorSchemeProvider>
        </I18nextProvider>
    )
}
