import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en/common.json';
import zh from './locales/zh/common.json';
import ru from './locales/ru/common.json';
import LanguageDetector from 'i18next-browser-languagedetector';

const lngDetector = new LanguageDetector(null, {
    order: ["localStorage", "cookie"],
    lookupCookie: "i18next",
    lookupLocalStorage: "i18next",
    caches: ["localStorage", "cookie"]
})


i18n
    .use(lngDetector) // 使用浏览器环境的语言检测
    .use(initReactI18next)
    .init({
        resources: {
            zh: { translation: zh },
            en: { translation: en },
            ru: { translation: ru }
        },
        lng: 'en', // 默认语言
        lngs: ['zh', 'en', 'ru'], // 支持的语言列表
        fallbackLng: 'zh', // 如果检测不到用户语言，将回退到的语言
        debug: true,
        interpolation: {
            escapeValue: false,
        },
    })
    .then(r => r)

export default i18n
