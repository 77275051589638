import {createStyles, Container, Group, ActionIcon, rem, useMantineTheme, Footer} from '@mantine/core';
import { IconBrandTwitter, IconBrandYoutube, IconBrandInstagram, IconBrandFacebook, IconBrandLinkedin, IconBrandTiktok, IconBrandWhatsapp, IconBrandTelegram, IconMail } from '@tabler/icons-react';
import HashSvg from '@/assets/HASHMiner.svg'
import React, { useState } from 'react';

const useStyles = createStyles((theme) => ({
    footer: {
        // marginTop: rem(120),
        // borderTop: `${rem(1)} solid ${
        //     theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
        // }`,
    },

    inner: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: theme.spacing.xl,
        paddingBottom: theme.spacing.xl,

        // [theme.fn.smallerThan('xs')]: {
        //     flexDirection: 'column',
        // },
    },

    links: {
        [theme.fn.smallerThan('xs')]: {
            // marginTop: theme.spacing.md,
        },
    },
}));


export function FooterSocial() {
    const theme = useMantineTheme();
    const { classes } = useStyles();
    const [emailAddress, setEmailAddress] = useState("heyingsg@gmail.com"); // 设置默认邮箱地址

    const handleClick = (link: string) => {
        window.open(link, "_blank")
    }

    return (
        <Footer height="" className={classes.footer}>
            <Container className={classes.inner}>
            <HashSvg style={{height: rem(28), width: rem(107), color: theme.colorScheme === 'dark' ? '#fff' : '#000'}} />
            {/* <p className="text-[#868e96] text-[0.875rem]">BEEVOLUTION TECHNOLOGY PTE. LTD. Founded in 2023</p> */}
                <Container className={classes.inner} style={{display: 'flex', justifyContent: 'center'}}>
                    <Group spacing={0} className={classes.links} position="right" noWrap>
                        <ActionIcon size="lg" onClick={()=> handleClick('mailto:heyingsg@gmail.com')} >
                            <IconMail size="1.05rem" stroke={1.5} />
                        </ActionIcon>
                        <ActionIcon size="lg" onClick={()=> handleClick('https://www.facebook.com/profile.php?id=61550848753292')}>
                            <IconBrandFacebook size="1.05rem" stroke={1.5} />
                        </ActionIcon>
                        <ActionIcon size="lg" onClick={()=> handleClick('https://twitter.com/@HashMiner358119')}>
                            <IconBrandTwitter size="1.05rem" stroke={1.5} />
                        </ActionIcon>
                        {/*<ActionIcon size="lg">*/}
                        {/*    <IconBrandYoutube size="1.05rem" stroke={1.5} />*/}
                        {/*</ActionIcon>*/}
                        {/*<ActionIcon size="lg">*/}
                        {/*    <IconBrandLinkedin size="1.05rem" stroke={1.5} />*/}
                        {/*</ActionIcon>*/}
                        <ActionIcon size="lg" onClick={()=> handleClick('https://www.instagram.com/hashminer50')}>
                            <IconBrandInstagram size="1.05rem" stroke={1.5} />
                        </ActionIcon>
                        <ActionIcon size="lg" onClick={()=> handleClick('https://www.tiktok.com/@hashminer4')}>
                            <IconBrandTiktok size="1.05rem" stroke={1.5} />
                        </ActionIcon>
                        <ActionIcon size="lg" onClick={()=> handleClick('https://wa.me/+16262130612')}>
                            <IconBrandWhatsapp size="1.05rem" stroke={1.5} />
                        </ActionIcon>
                        <ActionIcon size="lg" onClick={()=> handleClick('https://t.me/HashMiner01')}>
                            <IconBrandTelegram size="1.05rem" stroke={1.5} />
                        </ActionIcon>
                    </Group>
                </Container>
            </Container>
        </Footer>
    );
}
